<template>
  <BaseCoverPage :show-company-info="true">
    <div class="content-wrapper">
      <!-- Account Info Section -->
      <div class="text-center">
        <div class="relative w-24 h-24 mx-auto mb-8">
          <img :src="(accounts?.platform_logo || accounts?.image) ?? (accounts[0]?.platform_logo || accounts[0]?.image)" :alt="accounts?.name" class="w-full h-full rounded-full object-cover border-2 border-white shadow-lg" @error="handleImageError" />
          <img :src="getSocialImageRounded(accounts?.account_type || accounts[0]?.account_type)" :alt="accounts?.account_type" class="absolute -bottom-1 -right-1 w-8 h-8 rounded-full border-2 border-white shadow-md" />
        </div>
      </div>

      <!-- Title Section -->
      <div class="text-center">
        <h1 class="text-7xl font-normal mb-12">{{ accounts?.name || accounts?.platform_name }}</h1>
        <h2 class="text-5xl font-normal mb-6">{{ getReportName }}</h2>
        <div class="date-range py-2 px-6 mx-auto border-2 border-[#333] !rounded-full">
          {{ getReportDate }}
        </div>
      </div>
    </div>
  </BaseCoverPage>
</template>

<script setup>
import { computed } from 'vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import useDateFormat from "@common/composables/useDateFormat"
import BaseCoverPage from './BaseCoverPage.vue'

const props = defineProps({
  reportDate: {
    type: String,
    default: '',
  },
  accounts: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  },
})

const { handleImageError, getSocialImageRounded } = useComposerHelper()
const { momentWrapper } = useDateFormat()

const getReportDate = computed(() => {
  if (!props.reportDate) return

  const splitDates = props.reportDate.split(' - ')
  if (splitDates.length < 2) return

  const formattedStartDate = momentWrapper(splitDates[0]).formatDate()
  const formattedEndDate = momentWrapper(splitDates[1]).formatDate()

  return `${formattedStartDate} - ${formattedEndDate}`
})

const getReportName = computed(() => {
  if (!props.type) return
  if (props.type === 'twitter') return 'X (Twitter) Analytics'
  if (props.type === 'individual') return 'Individual Overview Report';
  const format = (props.type.charAt(0).toUpperCase() + props.type.slice(1)).replace(/-/g, ' ')
  return `Detailed Insights Report - ${format}`
})
</script>

<style lang="less" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 100%;
  position: relative;
  z-index: 1;
}

h1, h2 {
  color: #333;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.date-range {
  color: #333;
  background: white;
  font-size: 18px;
  width: fit-content;
  border: 2px solid #333;
  color: #333;
  padding: 0.5rem 2rem;
  display: inline-block;
}

.generated-date {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  p {
    font-size: 14px;
    color: #666;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
</style>
