<template>
  <div class="px-2">
    <p v-show="isAdmin" class="bg-[#ffd372] font-0-87rem my-2 px-4 py-2 rounded-xl text-center">You are about to purchase these limits on behalf of the Super Admin (<span class="text-blue-400 font-medium">{{superAdmin.email}}</span>). Please make sure to use their email address while making the purchase.</p>

    <!--      Choose Limits         -->
    <div class="grid grid-cols-4 justify-center gap-6 mt-8">
      <!--workspaces-->
      <div
        class="input-card"
        @wheel.prevent="changeItemValue($event, 'workspaces')"
      >
        <div class="click-btn" @click="decrementNumWorkspaces">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">Workspaces
            <img
              v-tooltip="{ content: toolTipContent('workspaces'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="workspaceQuantity"
            v-model="addon_price.monthly.workspaces.quantity"
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
          />
          <span class="price"
            >${{
              addon_price.monthly.workspaces.price *
              addon_price.monthly.workspaces.quantity
            }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumWorkspaces">
          <button class="spinner-increment">+</button>
        </div>
      </div>
      <!--team members-->
      <div
        class="input-card"
        @wheel.prevent="changeItemValue($event, 'members')"
      >
        <div class="click-btn" @click="decrementNumTeammember">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">Team Members
            <img
              v-tooltip="{ content: toolTipContent('members'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="teamMemberquantity"
            v-model="addon_price.monthly.members.quantity"
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="qtyInputLoose"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
          />
          <span class="price"
            >${{
              addon_price.monthly.members.price *
              addon_price.monthly.members.quantity
            }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumTeammember">
          <button class="spinner-increment">+</button>
        </div>
      </div>
      <!--social accounts-->
      <div
        class="input-card"
        @wheel.prevent="changeItemValue($event, 'accounts')"
      >
        <div class="click-btn" @click="decrementNumSocialAcc">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">Social Accounts
            <img
              v-tooltip="{ content: toolTipContent('profiles'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="socialAccQuantity"
            v-model="addon_price.monthly.profiles.quantity"
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="qtyInputLoose"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
          />
          <span class="price"
            >${{
              addon_price.monthly.profiles.price *
              addon_price.monthly.profiles.quantity
            }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumSocialAcc">
          <button class="spinner-increment">+</button>
        </div>
      </div>
      <!--Blogs-->
      <div class="input-card" @wheel.prevent="changeItemValue($event, 'blogs')">
        <div class="click-btn" @click="decrementNumBlogPublish">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">Blogs (Publishing)
            <img
              v-tooltip="{ content: toolTipContent('blogs'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="BlogPublishQuantity"
            v-model="addon_price.monthly.blogs.quantity"
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="qtyInputLoose"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
          />
          <span class="price"
            >${{
              addon_price.monthly.blogs.price *
              addon_price.monthly.blogs.quantity
            }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumBlogPublish">
          <button class="spinner-increment">+</button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 justify-center gap-6 mt-8">
      <!--automation campaign-->
      <div
        class="input-card"
        @wheel.prevent="changeItemValue($event, 'campaigns')"
      >
        <div class="click-btn" @click="decrementNumAutomateC">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">Automation Campaigns
            <img
              v-tooltip="{ content: toolTipContent('campaigns'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="AutomateCampaignsQuantity"
            v-model.number="addon_price.monthly.automation_campaigns.quantity"
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="qtyInputLoose"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
            step="5"
          />
          <span class="price"
            >${{
              addon_price.monthly.automation_campaigns.price *
              addon_price.monthly.automation_campaigns.quantity
            }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumAutomateC">
          <button class="spinner-increment">+</button>
        </div>
      </div>
      <!--Media Storage-->
      <div
        class="input-card"
        @wheel.prevent="changeItemValue($event, 'storage')"
      >
        <div class="click-btn" @click="decrementNumMediaStorage">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">Media Storage (GBs)
            <img
              v-tooltip="{ content: toolTipContent('storage'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="MediaStorageQuantity"
            v-model.number="addon_price.monthly.media_storage.quantity"
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="qtyInputLoose"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
            step="5"
          />
          <span class="price"
            >${{
              addon_price.monthly.media_storage.price *
              addon_price.monthly.media_storage.quantity
            }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumMediaStorage">
          <button class="spinner-increment">+</button>
        </div>
      </div>
      <!--caption generation (AI Text)-->
      <div
        class="input-card"
        @wheel.prevent="changeItemValue($event, 'credits')"
      >
        <div class="click-btn" @click="decrementNumCaptionGeneration">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">AI Text Credits (words)
            <img
              v-tooltip="{ content: toolTipContent('text-credits'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="CaptionGenerationQuantity"
            v-model.number="
              addon_price.monthly.caption_generation_credit.quantity
            "
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="qtyInputLoose"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
            step="5"
          />
          <span class="price"
            >${{ getCaptionGenerationPrice.toFixed(2) }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumCaptionGeneration">
          <button class="spinner-increment">+</button>
        </div>
      </div>
      <!--image generation-->
      <div class="input-card" @wheel.prevent="changeItemValue($event, 'image')">
        <div class="click-btn" @click="decrementNumImageGeneration">
          <button class="spinner-decrement">-</button>
        </div>
        <div class="click-input">
          <span class="name">AI Image Credits
            <img
              v-tooltip="{ content: toolTipContent('image-credits'), allowHTML: true }"
                src="@src/assets/img/integration/info_blue.svg"
                alt=""
                class="h-4 w-4 mb-1 cursor-pointer"
            ></span>
          <input
            id="ImageGenerationQuantity"
            v-model.number="
              addon_price.monthly.image_generation_credit.quantity
            "
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="qtyInputLoose"
            min="0"
            onkeydown="return false"
            pattern="[0-9]*"
            step="1"
          />
          <span class="price"
            >${{ Math.floor(getImageGenerationPrice) }}/month</span
          >
        </div>
        <div class="click-btn" @click="incrementNumImageGeneration">
          <button class="spinner-increment">+</button>
        </div>
      </div>
    </div>
    <!--      total bill         -->
    <div
      class="flex justify-center items-center mt-8 border-2 border-solid border-gray-300 border-opacity-50 rounded-md px-3 py-4"
    >
      <div class="text-base">Your order billed</div>
      <div class="ml-4 flex w-max">
        <CstRadio
          name="bill-type"
          value="monthly"
          :model-value="bill_type"
          class="mr-3"
          @change="changeBillType"
        >
          <div class="flex flex-col">
            <span class="">Monthly</span>
          </div>
        </CstRadio>
        <CstRadio
          name="bill-type"
          value="annually"
          :model-value="bill_type"
          @change="changeBillType"
        >
          <div class="flex flex-col">
            <span class="">Annually</span>
          </div>
        </CstRadio>
      </div>
      <div class="ml-auto text-2xl font-bold"
        >${{ parseFloat(getAddonsPrice).toFixed(2) }}</div
      >
    </div>
    <div class="flex justify-end mt-2.5 mb-8">
      <i class="text-xs">Amount calculated does not include VAT & Sales Tax.</i>
    </div>

    <!--    Footer    -->
    <div class="flex justify-between mt-3.5 mb-5">
      <div class="flex items-center">
        <div v-show="bill_type === 'monthly' && getAddonsPrice < 10">
          <img
            src="@assets/img/composer/warning-icon.svg"
            alt=" "
            class="w-5 h-5 mx-2"
          />
          <span
            >Monthly billing is only available for orders greater than
            <span class="font-bold"> $10/month.</span></span
          >
        </div>
      </div>
      <div>
        <CstButton
          v-if="$route.name !== 'upgrade_addon'"
          size="large"
          variant="secondary"
          class="mr-3 w-28"
          text="Cancel"
          @click="$bvModal.hide(modalId)"
        />
        <CstButton
          v-if="$route.name === 'upgrade_addon'"
          size="large"
          variant="secondary"
          class="mr-3 w-28"
          text="Cancel"
          @click="$router.push({ name: 'workspaces' })"
        />
        <CstButton
          size="large"
          variant="primary"
          class="mr-3"
          :disabled="
            getAddonsPrice === 0 ||
            (bill_type === 'monthly' && getAddonsPrice < 10) ||
            isIncreasingLimitsLoader
          "
          :loading="isIncreasingLimitsLoader"
          text="Purchase"
          @click="purchaseAddonAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import proxy from '@common/lib/http-common'
import {increaseLimitsURL} from '@src/modules/setting/config/api-utils'
import CstRadio from '@ui/Radio/CstRadio'
import CstButton from '@ui/Button/CstButton'
import useWorkspace from "@common/composables/useWorkspace";
import {usePaddle} from "@modules/billing/composables/usePaddle";
import {useBilling} from "@modules/billing/composables/useBilling";
export default {
  name: 'IndividualLimitsComponent',
  components: {
    CstRadio,
    CstButton,
  },
  setup(){
    const {getSuperAdmin} = useWorkspace()
    const { openCheckout } = usePaddle();
    const { getUmPayloadForTransaction } = useBilling()
    return {
      getSuperAdmin,
      openCheckout,
      getUmPayloadForTransaction
    }
  },
  data() {
    return {
      loaders: {
        increase_limits: false,
      },
      modalId: 'increase-limits-dialog',
      bill_type: 'annually',
      addon_price: {
        monthly: {
          workspaces: {
            quantity: 0,
            price: 5,
            max: 100,
          },
          members: {
            quantity: 0,
            price: 5,
            max: 100,
          },
          profiles: {
            quantity: 0,
            price: 5,
            max: 100,
          },
          blogs: {
            quantity: 0,
            price: 5,
            max: 100,
          },
          automation_campaigns: {
            quantity: 0,
            price: 0.2,
            max: 1000,
          },
          media_storage: {
            quantity: 0,
            price: 0.5,
            max: 1000,
          },
          caption_generation_credit: {
            quantity: 0,
            price: 0.00075,
            max: 99000000,
          },
          image_generation_credit: {
            quantity: 0,
            price: 0.05,
            max: 20000,
          },
        },
      },
    }
  },

  computed: {
    getAddonsPrice() {
      const sumAmount =
        this.addon_price.monthly.workspaces.price *
          this.addon_price.monthly.workspaces.quantity +
        this.addon_price.monthly.members.price *
          this.addon_price.monthly.members.quantity +
        this.addon_price.monthly.profiles.price *
          this.addon_price.monthly.profiles.quantity +
        this.addon_price.monthly.blogs.price *
          this.addon_price.monthly.blogs.quantity +
        this.addon_price.monthly.automation_campaigns.price *
          this.addon_price.monthly.automation_campaigns.quantity +
        this.addon_price.monthly.media_storage.price *
          this.addon_price.monthly.media_storage.quantity +
        this.getCaptionGenerationPrice +
        this.getImageGenerationPrice

      if (this.bill_type === 'annually') return sumAmount * 12
      return sumAmount
    },
    isIncreasingLimitsLoader() {
      return this.loaders.increase_limits
    },
    getCaptionGenerationPrice() {
      let captionGenerationPrice =
        this.addon_price.monthly.caption_generation_credit.price
      if (this.addon_price.monthly.caption_generation_credit.quantity > 99000)
        captionGenerationPrice = 0.0005
      if (this.addon_price.monthly.caption_generation_credit.quantity > 1000000)
        captionGenerationPrice = 0.0003

      return (
        captionGenerationPrice *
        this.addon_price.monthly.caption_generation_credit.quantity
      )
    },

    getImageGenerationPrice() {
      return (
        this.addon_price.monthly.image_generation_credit.price *
        this.addon_price.monthly.image_generation_credit.quantity
      )
    },

    superAdmin() {
      return this.getSuperAdmin()
    },
    isAdmin () {
      if(!this.superAdmin) return false
      return this.superAdmin._id !== this.$store.getters.getProfile._id
    }
  },
  methods: {
    changeItemValue(event, type) {
      // to calculate mouse wheel up/ down
      let scale = 1
      scale += event.deltaY * -0.01
      scale = Math.min(Math.max(0.125, scale), 4)
      if (scale > 1) {
        switch (type) {
          case 'workspaces':
            this.incrementNumWorkspaces()
            break
          case 'members':
            this.incrementNumTeammember()
            break
          case 'accounts':
            this.incrementNumSocialAcc()
            break
          case 'blogs':
            this.incrementNumBlogPublish()
            break
          case 'campaigns':
            this.incrementNumAutomateC()
            break
          case 'storage':
            this.incrementNumMediaStorage()
            break
          case 'credits':
            this.incrementNumCaptionGeneration()
            break
          case 'image':
            this.incrementNumImageGeneration()
            break
        }
      } else {
        switch (type) {
          case 'workspaces':
            this.decrementNumWorkspaces()
            break
          case 'members':
            this.decrementNumTeammember()
            break
          case 'accounts':
            this.decrementNumSocialAcc()
            break
          case 'blogs':
            this.decrementNumBlogPublish()
            break
          case 'campaigns':
            this.decrementNumAutomateC()
            break
          case 'storage':
            this.decrementNumMediaStorage()
            break
          case 'credits':
            this.decrementNumCaptionGeneration()
            break
          case 'image':
            this.decrementNumImageGeneration()
            break
        }
      }
    },
    changeBillType(value) {
      this.bill_type = value
    },
    incrementNumWorkspaces() {
      if (
        this.addon_price.monthly.workspaces.quantity <
        this.addon_price.monthly.workspaces.max
      )
        this.addon_price.monthly.workspaces.quantity =
          this.addon_price.monthly.workspaces.quantity + 1
    },
    decrementNumWorkspaces() {
      const input = document.querySelector('#workspaceQuantity')

      if (input.value > 0) {
        this.addon_price.monthly.workspaces.quantity =
          this.addon_price.monthly.workspaces.quantity - 1
      }
    },

    incrementNumTeammember() {
      if (
        this.addon_price.monthly.members.quantity <
        this.addon_price.monthly.members.max
      )
        this.addon_price.monthly.members.quantity =
          this.addon_price.monthly.members.quantity + 1
    },
    decrementNumTeammember() {
      const input = document.querySelector('#teamMemberquantity')

      if (input.value > 0) {
        this.addon_price.monthly.members.quantity =
          this.addon_price.monthly.members.quantity - 1
      }
    },

    incrementNumSocialAcc() {
      if (
        this.addon_price.monthly.profiles.quantity <
        this.addon_price.monthly.profiles.max
      )
        this.addon_price.monthly.profiles.quantity =
          this.addon_price.monthly.profiles.quantity + 1
    },
    decrementNumSocialAcc() {
      const input = document.querySelector('#socialAccQuantity')

      if (input.value > 0) {
        this.addon_price.monthly.profiles.quantity =
          this.addon_price.monthly.profiles.quantity - 1
      }
    },

    incrementNumBlogPublish() {
      if (
        this.addon_price.monthly.blogs.quantity <
        this.addon_price.monthly.blogs.max
      )
        this.addon_price.monthly.blogs.quantity =
          this.addon_price.monthly.blogs.quantity + 1
    },
    decrementNumBlogPublish() {
      const input = document.querySelector('#BlogPublishQuantity')

      if (input.value > 0) {
        this.addon_price.monthly.blogs.quantity =
          this.addon_price.monthly.blogs.quantity - 1
      }
    },

    incrementNumAutomateC() {
      if (
        this.addon_price.monthly.automation_campaigns.quantity <
        this.addon_price.monthly.automation_campaigns.max
      )
        this.addon_price.monthly.automation_campaigns.quantity =
          this.addon_price.monthly.automation_campaigns.quantity + 5
    },
    decrementNumAutomateC() {
      const input = document.querySelector('#AutomateCampaignsQuantity')

      if (input.value > 0) {
        this.addon_price.monthly.automation_campaigns.quantity =
          this.addon_price.monthly.automation_campaigns.quantity - 5
      }
    },

    incrementNumMediaStorage() {
      if (
        this.addon_price.monthly.media_storage.quantity <
        this.addon_price.monthly.media_storage.max
      )
        this.addon_price.monthly.media_storage.quantity =
          this.addon_price.monthly.media_storage.quantity + 1
    },
    decrementNumMediaStorage() {
      const input = document.querySelector('#MediaStorageQuantity')

      if (input.value > 0) {
        this.addon_price.monthly.media_storage.quantity =
          this.addon_price.monthly.media_storage.quantity - 1
      }
    },
    incrementNumCaptionGeneration() {
      if (
        this.addon_price.monthly.caption_generation_credit.quantity <
        this.addon_price.monthly.caption_generation_credit.max
      )
        this.addon_price.monthly.caption_generation_credit.quantity =
          this.addon_price.monthly.caption_generation_credit.quantity + 1000
    },
    decrementNumCaptionGeneration() {
      const input = document.querySelector('#CaptionGenerationQuantity')

      if (input.value > 0) {
        this.addon_price.monthly.caption_generation_credit.quantity =
          this.addon_price.monthly.caption_generation_credit.quantity - 1000
      }
    },
    incrementNumImageGeneration() {
      if (
        this.addon_price.monthly.image_generation_credit.quantity <
        this.addon_price.monthly.image_generation_credit.max
      )
        this.addon_price.monthly.image_generation_credit.quantity =
          this.addon_price.monthly.image_generation_credit.quantity + 100
    },
    decrementNumImageGeneration() {
      const input = document.querySelector('#ImageGenerationQuantity')

      if (input.value > 0) {
        this.addon_price.monthly.image_generation_credit.quantity =
          this.addon_price.monthly.image_generation_credit.quantity - 100
      }
    },

    async purchaseAddonAction() {

      this.loaders.increase_limits = true
      const self = this

      // eslint-disable-next-line camelcase
      const add_ons_ids = {
        develop: {
          add_ons_monthly: 35242,
          add_ons_yearly: 35243,
        },
        staging: {
          add_ons_monthly: 35244,
          add_ons_yearly: 35245,
        },
        'qa-features': {
          add_ons_monthly: 35242,
          add_ons_yearly: 35243,
        },
        uat: {
          add_ons_monthly: 0,
          add_ons_yearly: 0,
        },
        production: {
          add_ons_monthly: 593117,
          add_ons_yearly: 564649,
        },
      }

      const passThrough = {
        ...this.addon_price.monthly,
        user_id: this.superAdmin._id,
        email: this.superAdmin.email,
        is_admin: this.isAdmin,
        admin_id: this.isAdmin ? this.$store.getters.getProfile._id : null,
        um_payload: await this.getUmPayloadForTransaction()
      }

      const productId =
        this.bill_type === 'monthly'
          ? add_ons_ids[process.env.VUE_APP_ENVIRONMENT].add_ons_monthly
          : add_ons_ids[process.env.VUE_APP_ENVIRONMENT].add_ons_yearly
      proxy
        .post(increaseLimitsURL, {
          paddle_id: productId,
          price: this.getAddonsPrice,
          email: this.superAdmin.email,
          passthrough: passThrough,
          title: null,
          bill_type: this.bill_type,
        })
        .then(async (resp) => {
          const respPayload = resp.data
          if (respPayload.status) {

            if(respPayload.url) {  // need to create new addon subscription
              const paddleOptions = {
                override: respPayload.url,
                successCallback: function (data) {
                  self.triggerGTMEvent(data, 'addon')
                  self.triggerFBEvent(data, 'addon')
                  self.upgradeAddonsCallback(data)
                },
              }
              // eslint-disable-next-line no-undef
              await this.openCheckout(paddleOptions)
            } else if(respPayload.modifier_added) {
              self.upgradeAddonsCallback(respPayload)
            }
          } else {
            self.loaders.increase_limits = false
            this.$store.dispatch('toastNotification', {
              message: respPayload.message ?? 'Unable to purchase addons, please contact support.',
              type: 'error',
            })
          }
        })
        .catch((e) => {
          console.error(e)
          self.loaders.increase_limits = false
          this.$store.dispatch('toastNotification', {
            message: 'Unable to purchase addons, please contact support.',
            type: 'error',
          })
        })
    },
    upgradeAddonsCallback(data) {
      console.log('upgrade addons callback', data)
      this.loaders.increase_limits = true
      this.$store.dispatch('toastNotification', {
        message:
          'Your purchase has been successfully processed. Please wait for few seconds while your limits are upgraded.',
      })
      setTimeout(async () => {
        // reload the page to update the limits
        window.location.reload();
      }, 3000)
    },
    toolTipContent (addOnName) {
        switch(addOnName) {
            case 'workspaces':
                return `<p class="text-sm">1 Workspace = $${this.addon_price?.monthly?.workspaces?.price}/month</p>`
            case 'members':
                return `<p class="text-sm">1 Team Member = $${this.addon_price?.monthly?.members?.price}/month</p>`
            case 'profiles':
                return `<p class="text-sm">1 Social Account = $${this.addon_price?.monthly?.profiles?.price}/month</p>`
            case 'blogs':
                return `<p class="text-sm">1 Blog Site = $${this.addon_price?.monthly?.blogs?.price}/month</p>`
            case 'campaigns':
                return `<p class="text-sm">5 Automation Campaigns = $${this.addon_price?.monthly?.automation_campaigns?.price * 5}/month</p>`
            case 'storage':
                return `<p class="text-sm">1 GB Media Storage = $${this.addon_price?.monthly?.media_storage?.price}/month</p>`
            case 'text-credits':
              return `<p class="text-sm line-clamp-3 text-justify">1000 AI Text Credits (words) = $0.75/month. <br><span class="font-semibold">Beyond 99,000 credits =</span> $0.5/month <br><span class="font-semibold">Exceeding 1,000,000 credits =</span> $0.3/month. </p>`
            case 'image-credits':
                return `<p class="text-sm">100 AI Image Credits = $5/month</p>`
        }
    },
  },
}
</script>

<style lang="less">
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  // margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.input-card {
  height: 115px;
  @apply flex items-center justify-center rounded-lg  relative border-2 border-solid hover:border-blue-300 border-gray-300 border-opacity-50;
  .click-btn {
    max-width: 88px;
    @apply flex flex-col items-center justify-center w-full cursor-pointer z-50 select-none;
    .spinner-decrement {
      transition: all 0.2s ease-in-out;
      @apply bg-transparent border-0 outline-none text-5xl text-gray-800 self-end focus:outline-none;
    }
    .spinner-increment {
      transition: all 0.2s ease-in-out;
      @apply bg-transparent border-0 outline-none text-3xl text-primary-variant-1 self-baseline focus:outline-none;
    }
    &:hover > .spinner-increment {
      transform: scale(1.4);
    }
    &:hover > .spinner-decrement {
      transform: scale(1.5);
    }
  }
  .click-input {
    max-width: 210px;
    @apply flex flex-col items-center w-max text-center;
    input {
      border: 0 !important;
      padding: 0 !important;
      margin: 0;
      @apply text-cs-primary pointer-events-none font-extrabold w-full text-center text-4xl mt-10;
    }
    .name {
      @apply block absolute text-sm;
      top: 5px;
    }
    .price {
      @apply text-sm font-semibold my-2;
    }
  }
}
</style>
