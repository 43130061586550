/**
 *  This file is composable conversion of HelperMixin (src/modules/common/mixins/utils/helperMixin.js)
 *  You can move required methods from HelperMixin to this file and use it in your components
 */

import proxy from '@/src/modules/common/lib/http-common'
import { setUserPreferences , IMG_PROXY_KEY, IMG_PROXY_SALT, IMG_PROXY_URL } from '@/src/config/api-utils'

const createHmac = require('create-hmac')

const KEY = IMG_PROXY_KEY
const SALT = IMG_PROXY_SALT
const RESIZED_API_URL = IMG_PROXY_URL

export default function useNumber() {

  const setPreferenceStatus = async (key, value) => {
    try {
      await proxy.post(setUserPreferences, {
        key,
        value
      })
      return { success: true }
    } catch (e) {
      return { success: false, error: e }
    }
  }

  /**
   * Generates a signed and resized image URL.
   *
   * @param {string} url - The original image URL.
   * @param {number} [width=0] - The desired width of the resized image.
   * @param {number} [height=450] - The desired height of the resized image.
   * @param {string} [resizingType='auto'] - The type of resizing to apply.
   * @param {string} [gravity='sm'] - The gravity or focus point for the resizing.
   * @param {number} [enlarge=0] - Whether to allow enlarging the image.
   * @param {string} [extension='png'] - The desired image extension.
   * @returns {string} - The signed and resized image URL.
   */
  function getSignedResizedImageURL(
    url,
    width = 0,
    height = 450,
    resizingType = 'auto',
    gravity = 'sm',
    enlarge = 0,
    extension = 'png'
  ) {
    // Signing the URL and Calculating the HMAC digest using SHA256
    const sign = (salt, target, secret) => {
      const hexToBin = (hex) => {
        const buffer = Buffer.from(hex, 'hex')
        if (buffer.length === 0) {
          throw new Error('Key/Salt expected to be hex-encoded string')
        }
        return buffer
      }

      const saltBin = hexToBin(salt)
      const keyBin = hexToBin(secret)

      // Create message by concatenating salt and target as raw binary
      const message = Buffer.concat([saltBin, Buffer.from(target, 'utf8')])

      // Generate HMAC using the binary key
      const hmac = createHmac('sha256', keyBin)
      hmac.update(message)
      const signature = hmac.digest()

      // Take first 32 bytes and base64 encode
      const truncatedSignature = signature.slice(0, 32)
      return Buffer.from(truncatedSignature)
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
    }

    // Check if the image is gif
    if (/.gif/gi.test(url)) {
      return url
    } else {
      // Construct the path with all parameters
      const path = `/resize:${resizingType}:${width}:${height}:${gravity}:${enlarge}/plain/${encodeURIComponent(url)}`
      const signature = sign(SALT, path, KEY)
      return `${RESIZED_API_URL}${signature}${path}`
    }
  }

  /**
   * Generates a resized image URL based on the provided parameters.
   *
   * @param {string} url - The original image URL.
   * @param {number} [width=0] - The desired width of the resized image.
   * @param {number} [height=450] - The desired height of the resized image.
   * @param {string} [resizingType='auto'] - The type of resizing to apply.
   * @param {string} [gravity='sm'] - The gravity or focus point for the resizing.
   * @param {number} [enlarge=0] - Whether to allow enlarging the image beyond its original size.
   * @returns {string} - The URL of the resized image or the original URL if resizing is not applicable.
   */
  function getResizedImageURL(
    url,
    width = 0,
    height = 450,
    resizingType = 'auto',
    gravity = 'sm',
    enlarge = 0
  ) {
    // Check if the image is gif
    try {
      if (/.gif/gi.test(url)) {
        return url
      } else {
        return this.getSignedResizedImageURL(url, width, height, resizingType, gravity, enlarge)
      }
    } catch (e) {
      // console.error(e)
      return url
    }
  }

  /**
   * Validates an email address using a regular expression.
   *
   * This function checks if the provided email address matches
   * the standard pattern for emails.
   *
   * @param {string} email - The email address to validate.
   * @returns {boolean} - Returns true if the email is valid, else false.
   */
  function validateEmail(email) {
    // Regular expression pattern for email validation
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Test the email against the regular expression
    return re.test(email)
  }

  /**
   * Gets the user's IP address using a third-party service.
   *
   * This function makes an API call to ipify.org to retrieve
   * the client's public IP address.
   *
   * @returns {Promise<string>} - Returns a promise that resolves to the user's IP address.
   */
  async function getUserIP() {
    try {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      return data?.ip || null
    } catch (error) {
      console.error('Failed to fetch user IP:', error)
      return null
    }
  }

  return {
    getResizedImageURL,
    getSignedResizedImageURL,
    validateEmail,
    getUserIP,
    setPreferenceStatus
  }
}
